import {
  getAccountDetails,
  getBankDetailsSchema,
  getConfirmationSchema,
  getCustomerDetailsSchema,
  getPaymentAndSubmissionSchema,
  getSignatureSchema,
  getTradeDetailSchema,
  welcomeValidation,
} from "./Steppers/StepsFields";

export const schemas = [
  welcomeValidation,
  getCustomerDetailsSchema,
  getAccountDetails,
  getTradeDetailSchema,
  getPaymentAndSubmissionSchema,
  getBankDetailsSchema,
  welcomeValidation,
  getSignatureSchema,
  welcomeValidation,
  welcomeValidation,
  getConfirmationSchema,
];
