import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { dataURLtoFile } from "../../../utils/dataURLtoFile";
import { useGetSfCustomerSignature } from "../srfCustomerAPI";
import ConditionOfCarriageContent from "./ConditionOfCarriage.jsx/ConditionOfCarriageContent";

const paymentTermsValue = {
  SDN: "7 day net",
  FDN: "14 day net",
  TDN: "21 day net",
  TNDN: "30 day net",
};

function PreviewDetails({ isCustomerAdmin }) {
  const { getValues, watch } = useFormContext();
  const watchedValues = watch();
  const [imageSrc, setImageSrc] = useState(null);
  const customerId = getValues("customerId");
  const { data, isLoading } = useGetSfCustomerSignature(customerId);

  const {
    createdBy,
    createdDate,
    lastModifiedBy,
    lastModifiedDate,
    imageFileName,
    imagePath,
    mimeType,
    customerId: cusID,
    customerStatus,
    signUrl,
    secretToken,
    active,
    approvedDate,
    adminStatus,
    confirmedDate,
    signRequired,
    signaturePersonName,
    signaturePersonPosition,
    signatureDate,
    signature,
    paymentTerms,
    creditApplicationModalType,
    ...showData
  } = watchedValues;

  const signatureDetails = {
    signaturePersonName,
    signaturePersonPosition,
    signatureDate,
  };

  const paymentTerm = paymentTermsValue[paymentTerms];
  const onlyForAdminPreview = {
    creditApplicationModalType,
    ...showData,
    paymentTerm,
    approvedDate,
    adminStatus,
    confirmedDate,
  };

  useEffect(() => {
    if (customerId && !isLoading) {
      const file = dataURLtoFile(
        `data:image/png;base64,${data}`,
        "signature.png"
      );
      const url = URL.createObjectURL(file);
      setImageSrc(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [customerId, data, getValues, isLoading]);

  const renderSection = (title, data) => (
    <Card className="my-3">
      <Card.Header>{title}</Card.Header>
      <ListGroup variant="flush">
        {data &&
          Object.entries(data).map(([key, value]) => (
            <ListGroupItem key={key} className="border p-2">
              <Container fluid>
                <Row>
                  <Col md={4}>
                    <strong>{formatKey(key)}:</strong>
                  </Col>
                  <Col md={8}>{renderValue(value)}</Col>
                </Row>
              </Container>
            </ListGroupItem>
          ))}
      </ListGroup>
    </Card>
  );

  const formatKey = (key) => {
    const formattedKey = key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());

    // Check if the formattedKey is an integer in a string
    if (!isNaN(formattedKey) && Number.isInteger(parseFloat(formattedKey))) {
      return (parseInt(formattedKey, 10) + 1).toString();
    }

    return formattedKey;
  };

  const renderValue = (value) => {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return (
        <ListGroup variant="flush" className="border-start p-2">
          {Object.entries(value).map(([nestedKey, nestedValue]) => (
            <ListGroupItem key={nestedKey} className="border p-2">
              <Container fluid>
                <Row>
                  <Col md={4}>
                    <strong>{formatKey(nestedKey)}:</strong>
                  </Col>
                  <Col md={8}>{renderValue(nestedValue)}</Col>
                </Row>
              </Container>
            </ListGroupItem>
          ))}
        </ListGroup>
      );
    } else if (Array.isArray(value)) {
      return (
        <ListGroup variant="flush" className="border-start p-2">
          {value.map((item, index) => (
            <ListGroupItem key={index} className="border p-2">
              {renderValue(item)}
            </ListGroupItem>
          ))}
        </ListGroup>
      );
    } else {
      return value;
    }
  };

  return (
    <div style={{ textTransform: "initial" }}>
      <div id="printable-area">
        {showData.personalInfo &&
          renderSection(
            "Personal Details",
            isCustomerAdmin ? onlyForAdminPreview : showData
          )}
        {/* {showData.accountsTeam &&
          renderSection("Account Details", showData.accountsTeam)}
        {showData.businessInfo &&
          renderSection("Business Details", showData.businessInfo)}
        {showData.tradeDetails &&
          renderSection("Trade Details", showData.tradeDetails)}
        {showData.companyDirectors &&
          renderSection("Company Directors", showData.companyDirectors)}
        {showData.bankDetails &&
          renderSection("Bank Details", showData.bankDetails)} */}
        {/* {showData.signature &&
          renderSection("Signature", { Signature: "See Below" })} */}
        <ConditionOfCarriageContent />
        {/* {renderSection("Person Name", signaturePersonName)}
        {renderSection("Position", signaturePersonPosition)} */}
        {renderSection("Signature Details", signatureDetails)}
        {watchedValues.signature && (
          <Card className="my-3">
            <Card.Body>
              <img
                src={URL.createObjectURL(watchedValues.signature)}
                alt="Signature"
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "contain",
                  border: "1px solid #dee2e6",
                }}
              />
            </Card.Body>
          </Card>
        )}
        {watchedValues?.signUrl && (
          <Card className="my-3">
            <Card.Body>
              {imageSrc && (
                <img
                  src={imageSrc}
                  alt="Signature"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                    border: "1px solid #dee2e6",
                  }}
                />
              )}
            </Card.Body>
          </Card>
        )}
        {/* {renderSection("Date", signatureDate)} */}
      </div>
    </div>
  );
}

export default PreviewDetails;
