import React from "react";
import { modalTypeEnum } from "./StepsFields";

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const WelcomeDetails = ({ onNext, modalType }) => {
  const applyInstructions = [
    "Apply online in 5-10 minutes",
    "Receive response in 48 hours",
  ];

  return (
    <div className="welcome-details p-4">
      <h3 className="welcome-header">
        Welcome to Swift {modalTypeEnum[modalType]} Services
      </h3>
      <p className="welcome-content">
        {`Thank you for wishing to apply for a credit account with Swift ${capitalizeFirstLetter(
          modalTypeEnum[modalType].toLowerCase()
        )}
        Services.`}
      </p>

      <h3 className="welcome-header">Who We Are</h3>
      <p className="welcome-content">
        Swift Transport is a leading, independent, and wholly Australian-owned
        company specializing in transport and logistics. We proudly serve
        clients across Sydney, Melbourne, Brisbane, Adelaide, and Perth.
      </p>

      <ul className="welcome-content">
        <li>
          Market Leader: Swift is a leading player in the Metropolitan transport
          and logistics market in Australia, servicing major cities like Sydney,
          Melbourne, Brisbane, Adelaide, and Perth.
        </li>
        <li>
          Independent and Australian-Owned: Swift is an independent, wholly
          Australian-owned private company, known for its pioneering role in the
          transport and logistics industry.
        </li>
        <li>
          Diverse Fleet: The company operates a diverse mix of over 300
          subcontracted and company-owned vehicles, including cars,
          semi-trailers, and specialized vehicles like crane trucks and
          container transport.
        </li>
        <li>
          Commitment to Excellence: Swift is dedicated to providing high-quality
          transport services, continually adapting to the changing logistics
          landscape to meet client needs.
        </li>
      </ul>

      <h3 className="welcome-header">Our Services</h3>
      <ul className="welcome-content">
        <li>
          Urgent on Demand Couriers and Taxi Trucks: Fast and reliable delivery
          services for urgent needs.
        </li>
        <li>
          Container Pick Ups: Efficient handling of import and export cargo.
        </li>
        <li>
          3PL Warehousing & Distribution: Comprehensive storage and distribution
          solutions.
        </li>
        <li>
          Specialized Vehicles: From small envelopes to semi-trailers, we have
          the right vehicle for every job.
        </li>
      </ul>

      <h3 className="welcome-header">Our Commitment</h3>
      <p className="welcome-content">
        At Swift, we are dedicated to innovation and excellence. Our team of
        professional drivers, sales, administration, and operational personnel
        are focused on meeting our clients’ requirements with integrity,
        passion, and a commitment to safety.
      </p>

      <h3 className="welcome-header">Why Choose Us?</h3>
      <ul className="welcome-content">
        <li>
          Market Leader: Largest logistics provider for major freight forwarders
          and shipping lines in Sydney.
        </li>
        <li>
          Diverse Fleet: Over 300 vehicles, including specialized options like
          power tailgates and crane trucks.
        </li>
        <li>
          Customer-Centric: We value strong relationships and strive to exceed
          expectations.
        </li>
      </ul>

      <h3 className="welcome-header">Join Us</h3>
      <p className="welcome-content">
        Experience the Swift difference. Partner with us for all your transport
        and logistics needs.
      </p>

      {applyInstructions.map((instruction, index) => (
        <div className="mb-3 welcome-content" key={index}>
          {instruction}
        </div>
      ))}

      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={onNext}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default WelcomeDetails;
