import React from "react";
import "../SwiftTrafficSignup/swiftTrafficSignup.scss";
import { getUserData } from "../../rtk-slice/globalSlice";
import { useSelector } from "react-redux";
import LoginForDriver from "../SwiftTrafficSignup/components/LoginForDriver";
import SafeOperatingProcedureForm from "./components/SafeOperatingProcedureForm";

function SafeOperatingProcedure() {
  const userData = useSelector(getUserData);
  return (
    <div className="swift-traffic">
      {userData && userData.success ? (
        <SafeOperatingProcedureForm />
      ) : (
        <LoginForDriver />
      )}
    </div>
  );
}

export default SafeOperatingProcedure;
