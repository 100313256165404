import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import {
  MdOutlineCancel,
  MdOutlineSettingsBackupRestore,
} from "react-icons/md";
import { RiFileDownloadLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import ModalTab from "../../../../components/Modal";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import Spinner from "../../../../components/Spinner/Spinner";
import TableComp from "../../../../components/Table";
import { SelectColumnFilter } from "../../../../components/Table/Filters";
import {
  CREDIT_APPLICATION,
  FINANCE_APPLICATION_CONTAINER,
} from "../../../../constants/routes";
import {
  useGetSfCustomerAdmin,
  useUpdateCustomerStatus,
} from "../../../../hooks/useFinanceApplication";
import {
  NoteCell,
  wrapCell,
} from "../../../SurchargeMaintenance/components/CommonCell";
import { ColumnFilter } from "../../../UserManagement/UsersDisplay";
import { CommonBoxWithHeader } from "../Common";
import UpdateStatusModal from "../UpdateStatusModal";
import { modalTypeEnum } from "../../../../components/CreditApplication/Steppers/StepsFields";

const ButtonWithTooltip = ({ variant, onClick, icon, tooltipText }) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-${tooltipText}`}>{tooltipText}</Tooltip>}
  >
    <Button variant={variant} onClick={onClick}>
      {icon}
    </Button>
  </OverlayTrigger>
);

function Container() {
  const { data, isLoading } = useGetSfCustomerAdmin(modalTypeEnum.containers);
  const [viewUpdate, setViewUpdate] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const navigate = useNavigate();
  const updateCustomerStatus = useUpdateCustomerStatus(
    modalTypeEnum.containers
  );
  const queryClient = useQueryClient();

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    setShowConfirmModal(false);
  };

  const handleConfirmButtonClick = (action, message) => {
    setConfirmAction(() => action);
    setConfirmMessage(message);
    setShowConfirmModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
        Filter: ColumnFilter,
        maxWidth: 25,
        minWidth: 20,
        width: 20,
      },
      {
        Header: "Company",
        accessor: "companyName",
        Filter: ColumnFilter,
        maxWidth: 30,
        minWidth: 20,
        width: 20,
        Cell: ({ value }) => NoteCell(value),
      },
      {
        Header: wrapCell("Credit Limit"),
        accessor: "personalInfo.creditLimit",
        Filter: ColumnFilter,
        maxWidth: 15,
        minWidth: 12,
        width: 12,
      },
      {
        Header: "Email",
        accessor: "emailAddress",
        Filter: ColumnFilter,
        maxWidth: 25,
        minWidth: 24,
        width: 24,
        Cell: ({ value }) => (
          <div style={{ textTransform: "initial" }}>{NoteCell(value)}</div>
        ),
      },
      {
        Header: "Contact No.",
        accessor: "phoneNo",
        Filter: ColumnFilter,
        maxWidth: 15,
        minWidth: 12,
        width: 12,
      },
      {
        Header: wrapCell("Requested Amount"),
        accessor: "requestedAmount",
        Filter: ColumnFilter,
        maxWidth: 15,
        minWidth: 12,
        width: 12,
      },
      {
        Header: wrapCell("Admin Status"),
        accessor: "adminStatus",
        Filter: SelectColumnFilter,
        maxWidth: 15,
        minWidth: 12,
        width: 12,
      },
      {
        Header: "Action",
        Filter: ColumnFilter,
        disableFilters: true,
        maxWidth: 50,
        minWidth: 30,
        width: 35,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-around">
            <ButtonWithTooltip
              variant="secondary"
              onClick={() =>
                window.open(
                  `${FINANCE_APPLICATION_CONTAINER}/credit-application/containers/${row.original.secretToken}`,
                  "_blank"
                )
              }
              icon={<FaPencilAlt />}
              tooltipText="Edit"
            />
            <ButtonWithTooltip
              variant="primary"
              onClick={() =>
                navigate(
                  `${FINANCE_APPLICATION_CONTAINER}/view/credit-application/${row.original.secretToken}`
                )
              }
              icon={<FaEye />}
              tooltipText="View"
            />
            <ButtonWithTooltip
              variant="success"
              onClick={() =>
                handleConfirmButtonClick(() => {
                  /* Call Confirm API here */
                  updateCustomerStatus.mutate(
                    {
                      id: row?.original?.customerId,
                      status: "APPROVED",
                      data: { customerId: row?.original?.customerId },
                    },
                    {
                      onSettled: () => {
                        queryClient.invalidateQueries({
                          queryKey: [
                            "CustomerStatus",
                            row?.original?.customerId,
                          ],
                        });
                      },
                    }
                  );
                }, "Are you sure you want to approve?")
              }
              icon={<IoCheckmarkDoneSharp />}
              tooltipText="Approve"
            />
            <ButtonWithTooltip
              variant="danger"
              onClick={() =>
                handleConfirmButtonClick(() => {
                  /* Call Decline API here */
                  updateCustomerStatus.mutate(
                    {
                      id: row?.original?.customerId,
                      status: "REJECTED",
                      data: { customerId: row?.original?.customerId },
                    },
                    {
                      onSettled: () => {
                        queryClient.invalidateQueries({
                          queryKey: [
                            "CustomerStatus",
                            row?.original?.customerId,
                          ],
                        });
                      },
                    }
                  );
                }, "Are you sure you want to reject?")
              }
              icon={<MdOutlineCancel />}
              tooltipText="Reject"
            />
            <ButtonWithTooltip
              variant="warning"
              onClick={() => setViewUpdate(row?.original?.customerId)}
              icon={<MdOutlineSettingsBackupRestore />}
              tooltipText="Proceed"
            />
            <ButtonWithTooltip
              variant="info"
              onClick={() =>
                navigate(
                  `${FINANCE_APPLICATION_CONTAINER}/view/credit-application/${row.original.secretToken}`
                )
              }
              icon={<RiFileDownloadLine />}
              tooltipText="Download"
            />
          </div>
        ),
      },
    ],
    [navigate, queryClient, updateCustomerStatus]
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="p-5">
      <CommonBoxWithHeader title="Customers - Containers">
        <div className="application-btn">
          <Link
            to={`${CREDIT_APPLICATION}/containers`}
            target="_blank"
            className="unstyled-link"
          >
            <button type="button" className="credit-form-btn">
              Application Link
            </button>
          </Link>
        </div>
        <TableComp data={data} columns={columns} enableGlobalFilter={true} />
        {viewUpdate && (
          <ModalTab
            size="lg"
            centered
            show
            onHide={() => setViewUpdate(null)}
            title="SWIFT Container Services - Update Status"
            bodyClassName="d-flex justify-content-center"
            body={
              <UpdateStatusModal
                id={viewUpdate}
                setViewUpdate={setViewUpdate}
              />
            }
          />
        )}
      </CommonBoxWithHeader>
      <ConfirmationModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        message={confirmMessage}
      />
    </div>
  );
}

export default Container;
