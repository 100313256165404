import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./SafeOperatingProcedure.scss";
import Logo from "../../../images/SafeOperatingProcedure/logo.jpg";
import req1 from "../../../images/SafeOperatingProcedure/req1.jpg";
import req2 from "../../../images/SafeOperatingProcedure/req2.jpg";
import req3 from "../../../images/SafeOperatingProcedure/req3.jpg";
import req4 from "../../../images/SafeOperatingProcedure/req4.jpg";
import req5 from "../../../images/SafeOperatingProcedure/req5.jpg";
import req6 from "../../../images/SafeOperatingProcedure/req6.jpg";
import req7 from "../../../images/SafeOperatingProcedure/req7.jpg";
import req8 from "../../../images/SafeOperatingProcedure/req7.jpg";

const allReqImg = [req1, req2, req3, req4, req5, req6, req7, req8];

function SafeOperatingProcedurePdf() {
  return (
    <Container className="SafeOperatingProcedure">
      <div className="SafeOperatingProcedurePdfPage">
        <Row>
          <Col
            xs={12}
            sm={4}
            className="d-flex justify-content-center safeOpProCol"
          >
            <img width={120} height={60} src={Logo} alt="Logo" />
          </Col>
          <Col
            xs={12}
            sm={8}
            className="d-flex justify-content-center align-items-center safeOpProCol"
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "1.3rem",
                margin: 0,
              }}
            >
              Safe Operating Procedure
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={2} sm={2} className="safeOpProCol">
            Title:
          </Col>
          <Col className="safeOpProCol" xs={10} sm={4}>
            Checking Containers In & Out of the yard
          </Col>
          <Col className="safeOpProCol" xs={12} sm={2}>
            Document Number:
          </Col>
          <Col className="safeOpProCol" xs={12} sm={4}>
            SWI.NAT.SOP.033
          </Col>
        </Row>
        <Row>
          <Col className="safeOpProCol" xs={12} sm={2}>
            Purpose:
          </Col>
          <Col className="safeOpProCol" xs={12} sm={10}>
            The purpose is to ensure that all drivers have checked the container
            they are picking up or dropping off. Whether it be from the Swift
            Transport yard, Container Terminal or Clients Premises.
          </Col>
        </Row>
        <Row>
          <Col className="safeOpProCol" xs={12} sm={2}>
            Scope:
          </Col>
          <Col className="safeOpProCol" xs={12} sm={10}>
            This document applies to all Swift Transport drivers.
          </Col>
        </Row>
        <Row>
          <Col className="safeOpProCol" xs={12} sm={2}>
            PPE Required:
          </Col>
          <Col className="safeOpProCol" xs={12} sm={10}>
            <div className="safeProcedure-req">
              {allReqImg.map((img) => (
                <img src={img} alt="req" />
              ))}
            </div>
            <p style={{ textAlign: "center", margin: 0, paddingTop: "1rem" }}>
              Site option may include
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <Row>
              <Col className="safeOpProCol-tableHeader" xs={12}>
                Permit / Licence Required:
              </Col>
              <Col className="safeOpProCol sop-tableData" xs={12}>
                Heavy Vehicle Drivers Licence{" "}
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={4}>
            <Row>
              <Col className="safeOpProCol-tableHeader" xs={12}>
                Reference Documents:
              </Col>
              <Col className="safeOpProCol sop-tableData" xs={12}></Col>
            </Row>
          </Col>
          <Col xs={12} sm={4}>
            <Row>
              <Col className="safeOpProCol-tableHeader" xs={12}>
                Audit (SOPAC):
              </Col>
              <Col className="safeOpProCol sop-tableData" xs={12}>
                <Row>
                  <Col xs={6}>Internal</Col>
                  <Col xs={6}>3 Months </Col>
                </Row>
                <Row>
                  <Col xs={6}>Quality/Safety </Col>
                  <Col xs={6}>6 Monthly </Col>
                </Row>
                <Row>
                  <Col xs={6}>External</Col>
                  <Col xs={6}>Annually</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="p-4">
          <p>
            All drivers MUST do a pre-start checklist of their equipment, truck
            & trailer prior to each job.
          </p>
          <p>
            Check and ensure that all loaded containers have a seal before
            departure. If collecting a container that has been fumigated ensure
            it has been resealed and has a seal before departure and leaving the
            yard.
          </p>
          <p>
            Check your container to ensure it has the same seal number as to
            what is outlined on your paperwork.
            <strong>
              Any discrepancies MUST be reported to your supervisor, allocators
              or to Swift office immediately.
            </strong>
          </p>
          <p>
            Any equipment found to be damaged must be reported and should be
            replaced.
          </p>
          Ensure your container is restrained in the correct manner and is
          always secure.
          <p>
            Stop and check your load remains secure at regular intervals during
            your journey.
          </p>
          <p>Any incidents MUST be reported to your supervisor immediately.</p>
          <p>Approved By – Geoff De Santis Personnel & Compliance Manager </p>
        </div>
      </div>
    </Container>
  );
}

export default SafeOperatingProcedurePdf;
