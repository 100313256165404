import React from "react";

function ThankYou() {
  return (
    <div className="welcome-details text-center">
      <h2 className="welcome-header">Thank You for Your Application!</h2>
      <p className="welcome-content">
        Your credit account application is now under review by our
        administration team.
      </p>
      <p className="welcome-content">
        We will verify the information provided and notify you via the email
        address you provided within the next few days.
      </p>
      <p className="welcome-content">
        Thank you for choosing Swift Container Services for your credit needs.
        If you have any urgent inquiries, please contact our support team at
        support@swiftcontainers.com.
      </p>
      <p className="welcome-content">We look forward to serving you!</p>
    </div>
  );
}

export default ThankYou;
