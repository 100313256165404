import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../constants/config";
import { fetchService, postFile } from "../../utils/api";
import { errorToast, successToast } from "../../utils/toastFunc";

const url = `${config.baseUrl}${config.sfCustomer}`;
const secretKey =
  "mpnOKLhob4dyYYv7iaKfap2yngz2Sxrt0eUXdia9z6PrmmPcbc77hk2VO4o7APSA";
const headerObj = { secretKey };
const adminUrl = `${config.baseUrl}${config.AdminSfCustomer.updateCustomer}`;

export const useSfCustomer = (setSecretToken, isCustomerAdmin = false) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ formData }) => {
      const response = await postFile(
        isCustomerAdmin ? adminUrl : url,
        formData,
        headerObj,
        isCustomerAdmin ? "PUT" : "POST"
      );
      setSecretToken(response.secretToken);
    },
    onError: (err) => {
      errorToast({ mes: err.errorMessage });
    },
    onSuccess: () => successToast({ mes: "Form Submitted" }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["AdminCustomer"],
      });
    },
  });
};

export const useGetSfCustomer = (id) => {
  return useQuery({
    queryKey: ["creditApplication", id],
    queryFn: async () => {
      const response = await fetchService({
        url: `${config.sfCustomer}/${id}`,
      });
      return response;
    },
    enabled: !!id,
  });
};

export const useGetSfCustomerSignature = (id) => {
  return useQuery({
    queryKey: ["creditApplicationSignature", id],
    queryFn: async () => {
      const response = await fetch(
        `${config.baseUrl}/${config.sfCustomerSignature}/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "text/plain",
          },
        }
      );
      if (response.ok) {
        const signaturePic = await response.text();
        return signaturePic;
      }
      return null;
    },
    enabled: !!id,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
