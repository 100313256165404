import { yupResolver } from "@hookform/resolvers/yup";
import { generate } from "@pdfme/generator";
import { image, text } from "@pdfme/schemas";
import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormField } from "../../../components/Form";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import SignatureCanvasField from "../../../components/SignatureCanvas/SignatureCanvasField";
import config from "../../../constants/config";
import { doLogout, getUserData } from "../../../rtk-slice/globalSlice";
import { postFile } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import SafeOperatingProcedurePdf from "./SafeOperatingProcedurePdf";
import template from './template.json'

const validationSchema = Yup.object().shape({
  document: Yup.mixed().required("Signature is required"),
});

function SafeOperatingProcedureForm() {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const driverId = userData.userContext.userId;
  const { firstName, lastName } = userData.userDetails;
  const [isLoading, setIsLoading] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    setShowConfirmModal(false);
  };

  const handleConfirmButtonClick = (action) => {
    setConfirmAction(() => action);
    setShowConfirmModal(true);
  };

  const onSubmit = async (data) => {
    handleConfirmButtonClick(async () => {
      setIsLoading(true);
      const url =
        config.baseUrl + config.userDocuments.uploadFile + "/" + driverId;
      const formData = new FormData();
      const plugins = { text, image };
      const inputs = [
        {
          fullName: `${firstName} ${lastName ??  ""}`,
          date: moment().format("DD-MM-YYYY"),
          signature: data.document,
        },
      ];

      const pdf = await generate({ template, inputs, plugins });
      const blob = new Blob([pdf.buffer], { type: "application/pdf" });
      const file = new File([blob], "safe-operating-procedure-sign-off.pdf", {
        type: "application/pdf",
      });
      formData.append("document", file);

      const response = await postFile(url, formData);
      window.open(URL.createObjectURL(blob));

      if (response.data === "document uploaded successfully") {
        successToast({ mes: "Document Submitted" });
        dispatch(doLogout());
      } else {
        setIsLoading(false);
        errorToast({ mes: "Error Upload" });
      }
    });
  };

  return (
    <Container>
      <SafeOperatingProcedurePdf />
      <div className="eachPage mb-0">
        <p>
          <strong>Sign off</strong>
        </p>
        <p>
          I have read, understood, and agreed to comply with the content of this
          safe operating procedure and its required actions.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="align-items-center mt-2">
            <Col xs={12} sm={6} md={4}>
              <FormField
                className="Field_label"
                title="First Name"
                id="firstName"
                ratio={[2, 10]}
              >
                <input
                  type="text"
                  className="form-control"
                  value={firstName}
                  disabled
                />
              </FormField>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormField
                className="Field_label"
                title="Last Name"
                id="lastName"
                ratio={[2, 10]}
              >
                <input
                  type="text"
                  className="form-control"
                  value={lastName}
                  disabled
                />
              </FormField>
            </Col>
          </Row>
          <SignatureCanvasField
            label="Digital Signature"
            signatureError={errors?.document?.message}
            onSignatureChange={(value) => setValue("document", value)}
          />
          <div className="d-flex justify-content-center mt-5">
            <button
              type="submit"
              className="credit-form-btn py-2"
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </form>

        <ConfirmationModal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          onConfirm={handleConfirm}
          message="Are you sure you want to submit this file?"
        />
      </div>
    </Container>
  );
}

export default SafeOperatingProcedureForm;
