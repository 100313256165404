import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { FormField, TextBox, ToggleSwitch } from "../../../components/Form";
import Spinner from "../../../components/Spinner/Spinner";
import {
  useGetCustomerStatus,
  useUpdateCustomerStatus,
} from "../../../hooks/useFinanceApplication";
import "./UpdateStatusModal.scss";

const schema = Yup.object().shape({
  adminNotes: Yup.string()
    .required("Notes are required")
    .typeError("Notes are required"),
  accountId: Yup.string()
    .optional()
    .nullable()
    .max(15, "only 15 char are allowed"),
  requestedAmount: Yup.string().required(),
  approvedAmount: Yup.number()
    .required("Approved amount is required")
    .typeError("Approved amount is required"),
});

function UpdateStatusModal({ id, setViewUpdate }) {
  const { data, isError, isLoading } = useGetCustomerStatus(id);
  const updateCustomerStatus = useUpdateCustomerStatus(
    data?.creditApplicationModalType
  );
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (data) {
      reset({
        adminNotes: data.adminNotes,
        accountId: data.accountId,
        requestedAmount: data.requestedAmount,
        approvedAmount: 0,
        signRequired: data.signRequired,
        ...data,
      });
    }
  }, [data, reset]);

  const statusSubmit = (formData) => {
    const {
      accountId,
      adminNotes,
      requestedAmount,
      approvedAmount,
      customerId,
      secretToken,
      notes,
      signRequired: signRequest,
    } = formData;
    const updatedData = {
      accountId,
      adminNotes,
      requestedAmount,
      approvedAmount,
      customerId,
      notes,
      secretToken,
      signRequest,
    };
    updateCustomerStatus.mutate(
      { id, status: "PROCESSED", data: updatedData },
      {
        onSuccess: () => {
          setViewUpdate(null);
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: ["CustomerStatus", id],
          });
        },
      }
    );
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <Container className="updateStatusModal-box">
      <form
        className="updateStatusModal-form"
        onSubmit={handleSubmit(statusSubmit)}
      >
        <FormField
          className="Field_label text-start"
          title="Requested Amount"
          id="requestedAmount"
        >
          <div className="input-group">
            <span className="input-group-text" id="requestedAmount">
              $
            </span>
            <TextBox
              type="text"
              id="requestedAmount"
              register={register}
              errors={errors}
              placeholder="eg. $100"
              className="py-2"
              disabled
            />
          </div>
        </FormField>
        <FormField
          className="Field_label text-start"
          title="Approved Amount"
          id="approvedAmount"
          required
        >
          <div className="input-group">
            <span className="input-group-text" id="requestedAmount">
              $
            </span>
            <TextBox
              type="number"
              id="approvedAmount"
              register={register}
              errors={errors}
              placeholder="eg. $100"
              className="py-2"
            />
          </div>
        </FormField>
        <FormField
          className="Field_label text-start"
          title="Account ID"
          id="accountId"
        >
          <TextBox
            type="text"
            id="accountId"
            register={register}
            errors={errors}
            placeholder="eg. 12345678"
            className="py-2"
            getError={(error) => error?.accountId}
          />
        </FormField>
        <FormField
          title="Sign required"
          id="signRequired"
          className="Field_label text-start"
        >
          <ToggleSwitch
            type="text"
            register={register}
            errors={errors}
            id="signRequired"
            scale={1.2}
          />
        </FormField>
        <FormField
          className="Field_label text-start"
          title="Notes"
          required
          id="adminNotes"
        >
          <textarea
            id="adminNotes"
            placeholder="write about it"
            {...register("adminNotes")}
            className={`form-control ${errors.adminNotes ? "is-invalid" : ""}`}
            maxLength={200}
          />
          {errors.adminNotes && (
            <p className="invalid-feedback">{errors.adminNotes.message}</p>
          )}
        </FormField>
        <button type="submit" className="credit-form-btn">
          Submit
        </button>
      </form>
    </Container>
  );
}

export default UpdateStatusModal;
