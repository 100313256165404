import React from "react";
import "./Dashboard.scss";
import {
  user_management,
  runsheet,
  induction,
  InspectionReportIcon,
  VehicleIcon,
  PlannerIcon,
  DriversIcon,
  SurchargeIcon,
  FinanceIcon,
  NexusIcon,
} from "../../images/Dashboard-icons";
import * as Navigate from "../../constants/routes";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectServerEnvironment } from "../../rtk-slice/globalSlice";
import { hasModuleAccess } from "../../utils";
import {
  AUTO_RUNSHEET,
  DRIVERS,
  EXPORT_RELEASE_PLANNER,
  FINANCE_APPLICATION,
  INDUCTION,
  INDUCTION_LEARNING,
  INSPECTION_REPORT,
  MANUAL_RUNSHEET,
  REPORTING_TOOL,
  SURCHARGE_MAINTENANCE,
  USER_MANAGEMENT,
  VEHICLES,
} from "../../constants/enums";

const Dashboard = () => {
  const environment = useSelector(selectServerEnvironment);

  const items = [
    hasModuleAccess(USER_MANAGEMENT) && {
      title: "User Mangement",
      icon: user_management,
      linkTo: Navigate.USER_MANAGEMENT,
    },
    hasModuleAccess(MANUAL_RUNSHEET) && {
      title: "Manual Runsheet",
      icon: runsheet,
      linkTo: Navigate.MANUAL_RUNSHEET,
    },
    hasModuleAccess(AUTO_RUNSHEET) &&
      ["dev", "local"].includes(environment) && {
        title: "Auto Runsheet",
        icon: runsheet,
        linkTo: Navigate.AUTO_RUNSHEET,
      },
    hasModuleAccess(INDUCTION) && {
      title: "Hire",
      icon: induction,
      linkTo: Navigate.INDUCTION,
    },
    hasModuleAccess(INSPECTION_REPORT) && {
      title: "Inspection Report",
      icon: InspectionReportIcon,
      linkTo: Navigate.INSPECTION_REPORT,
    },
    hasModuleAccess(VEHICLES) && {
      title: "Vehicles",
      icon: VehicleIcon,
      linkTo: Navigate.VEHICLES,
    },
    hasModuleAccess(DRIVERS) && {
      title: "Drivers",
      icon: DriversIcon,
      linkTo: Navigate.DRIVERS_DASHBOARD,
    },
    hasModuleAccess(EXPORT_RELEASE_PLANNER) && {
      title: "Export Release Planner",
      icon: PlannerIcon,
      linkTo: Navigate.EXPORT_RELEASE_PLANNER,
    },
    hasModuleAccess(REPORTING_TOOL) && {
      title: "Reporting Tool",
      icon: PlannerIcon,
      linkTo: Navigate.REPORTING,
    },
    hasModuleAccess(INDUCTION_LEARNING) && {
      title: "Induction Learning",
      icon: induction,
      linkTo: Navigate.INDUCTION_LEARNING,
    },
    hasModuleAccess(SURCHARGE_MAINTENANCE) && {
      title: "Surcharge maintenance",
      icon: SurchargeIcon,
      linkTo: Navigate.SURCHARGE_MAINTENANCE,
    },
    hasModuleAccess(FINANCE_APPLICATION) && {
      title: "Finance Application",
      icon: FinanceIcon,
      linkTo: Navigate.FINANCE_APPLICATION,
    },
    {
      title: "Nexus",
      icon: NexusIcon,
      linkTo: Navigate.Nexus,
    },
  ];

  return (
    <div className="dashboard-page">
      <div className="dashboard-container">
        <div className="dashboard-title">Dashboard</div>
        <div className="dashboard-items">
          {items.map((item, index) => {
            if (!item) return null;
            return (
              <Link
                to={item.linkTo}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <div className="item-container">
                  <img src={item.icon} alt="navigation" className="icon" />
                  <div className="title">{item.title}</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
