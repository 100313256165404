import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import accountDetails from "../../../images/Credit-application/account-details.png";
import bankDetails from "../../../images/Credit-application/bank-details.png";
import companyDetailsIcon from "../../../images/Credit-application/company-details.png";
import completed from "../../../images/Credit-application/completed.png";
import paymentMethod from "../../../images/Credit-application/payment-method.png";
import signature from "../../../images/Credit-application/signature.png";
import termsAndConditions from "../../../images/Credit-application/terms.png";
import tradeDetails from "../../../images/Credit-application/trade-details.png";

const MultiStepProgressBar = ({ currentStep }) => {
  const stepPercentages = [0, 0, 2, 16, 30, 45, 58, 72, 88, 100];
  const stepPercentage = stepPercentages[currentStep] || 0;

  const icons = [
    {
      Icon: companyDetailsIcon,
      label: "Company Details",
    },
    {
      Icon: accountDetails,
      label: "Account Details",
    },
    {
      Icon: tradeDetails,
      label: "Trade Details",
    },
    {
      Icon: paymentMethod,
      label: "Payment Method",
    },
    {
      Icon: bankDetails,
      label: "Bank Details",
    },
    {
      Icon: termsAndConditions,
      label: "Terms and Conditions",
    },
    {
      Icon: signature,
      label: "Signature",
    },
    {
      Icon: completed,
      label: "Completed",
    },
  ];

  return (
    <ProgressBar percent={stepPercentage}>
      {icons.map(({ Icon, label }, index) => (
        <Step key={index}>
          {({ accomplished }) => (
            <div className="ProgressSingleBox">
              <div
                className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              >
                {/* <Icon /> */}
                <img width={30} height={30} src={Icon} alt="" />
              </div>
              <div className="progressLabel">{label}</div>
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
