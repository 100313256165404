import { useNavigate } from "react-router-dom";
import "../FinanceApplication.scss";
import { IoMdArrowRoundBack } from "react-icons/io";

export const CommonBoxWithHeader = ({ title, children }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="finance-back-btn">
        <button type="button" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack style={{ marginRight: "8px" }} />
          Back
        </button>
      </div>
      <div className="dashboard">
        <div className="dashboard_header">
          <h3 className="">{title}</h3>
        </div>
        <div className="dashboard_bottom">{children}</div>
      </div>
    </>
  );
};
