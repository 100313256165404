import forge from "node-forge";

const publicKey =
  "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3fokUNjIAYH7pwvsZ8RgtwfqlJ5u38/Eeg6Eobhym77YyvupGSXwFV2Z1/yCplKYoSXFwnSlEYGfWAv+eyac8sTIMcU8e1uuGeFi1EOSfvQMW/p6LUDa40HAED8JBM3U98RK4/vc2giZ1PzUFdXYEQLNdpTxpNuJ/KUSkzmGKQCWi9SVky38/jLTP/Zkl1me0x2xaXbUvnJ8ptHy/9UWGFsLZjaP9vAGz9djL54XW8uwzryYjsY1APoC0NWkeVJDWvUrEHNvDnzk9SnyvrDmvMRI/H9s0gEHOXii4+eBT7Qcyawy5kn0zIIdRixU+SIipX9TrHapTJHYES0Ek99JnwIDAQAB-----END PUBLIC KEY-----";

const publicKeyPem = publicKey;

export function encryptionHandler(data) {
  try {
    const enPublicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const jsonString = JSON.stringify(data);
    const encrypted = enPublicKey.encrypt(jsonString, "RSA-OAEP", {
      md: forge.md.sha256.create(),
    });

    return forge.util.encode64(encrypted);
  } catch (error) {
    console.error("Error encrypting data:", error);
    throw error;
  }
}
